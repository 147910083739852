import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Web3 from "web3";
import moment from "moment";

//config
import config from "../../config/index.js";

//ABI
import contractAbi from "../../ABI/MLMContract.json";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, bignumber } from "../../helper/Action.js";
import { getRoyaltyDataHistory } from "../../helper/axios.acion.js";

function RoyaltyHis() {
  const walletConnection = useSelector((state) => state?.walletConnection);
  const [RoyaltyData, setRoyaltyData] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (walletConnection.address) {
      
     
        getRoyaltyData();
    }
  }, [walletConnection?.address]);



  const getRoyaltyData=async()=>{

    const payload={
        address:walletConnection.address
    }

    const result = await getRoyaltyDataHistory(payload)

    console.log(result,'royalty')

    setRoyaltyData(result)
  }




  return (
    <>
      <div className="dashboard_table dashboard_table_1">
      <div className={`dashboard_header ${isExpanded ? 'expanded' : 'not-expanded'}`}
      >
          <div className="mobile_img_none">
            <img
                src={require("../../assets/images/dashboard/user_icon_9.png")}
                className="img-fluid"
                />
                <h6>Royalty Details</h6>
                </div>
                <img
              src={require("../../assets/images/dashboard/arrow.png")}
              className="img-fluid me-5 rotate"
              data-bs-toggle="collapse"
              href="#collapseExamplefive"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
                  onClick={toggleCollapse}
            />
                </div>
                <div className=" collapse collapse_content" id="collapseExamplefive">
                {RoyaltyData && RoyaltyData?.length!=0 ? (
                <div className="table-responsive">
                    <table class="table mt-4">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Date & Time</th>
                                {/* <th scope="col">User ID</th> */}
                                {/* <th scope="col">Address</th> */}
                                <th scope="col">Royalty Amount</th>
                                {/* <th scope="col">Royalty Amount</th> */}
                                {/* <th scope="col">Status</th> */}

                                
                            </tr>
                        </thead>
                        <tbody>
                        {RoyaltyData && RoyaltyData.map((item, index) => (
                            <tr>
                                <th scope="row">{index}</th>
                                <td>{moment(Number(item?.currentEnd * 1000)).format("lll")}</td>
                                {/* <td>{item?.id}</td> */}
                                {/* <td>{item.userAddress}</td> */}
                                <td>{item?.totalAmount/1e18} USD</td>
                                {/* <td>{item.status==1 ? 'Completed' : 'Pending'}</td> */}

                            </tr>
                        ))}
                            
                        </tbody>
                    </table>
                </div>
                ):(
                    <center><h6>No History</h6></center>
                )}
                </div>
            </div>
    </>
  );
}

export default RoyaltyHis;
